import { Suspense, lazy } from "react";
import { render } from "react-dom";
import AppLoader from "./components/atoms/AppLoader";

const App = lazy(() => import("./WrapperApp"));

const target = document.getElementById("root");

render(
  <Suspense fallback={<AppLoader />}>
    <App />
  </Suspense>,
  target
);
